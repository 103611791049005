import type BaseColumn from 'o365.controls.Grid.BaseColumn.ts';
import type BaseGridControl from 'o365.controls.Grid.BaseGrid.ts';
import BaseGridExtension from 'o365.controls.Grid.BaseExtension.ts';
import { createPopper } from 'popper';


declare module 'o365.controls.Grid.BaseGrid.ts' {
    interface BaseGridControl {
        header: BaseGridHeader;
    }
}

export default class BaseGridHeader<T extends BaseGridControl = BaseGridControl> extends BaseGridExtension<T> {
    static __extension = 'header';

    private _headerContainer: HTMLElement;

    /** Flips sizers on header cells from right side to left side */
    useLeftColumnSizers: false;

    protected initializeExtension() { };

    /** Handler for header mounted hook */
    handleMounted(options: {
        headerContainer: HTMLElement
    }) {
        this._headerContainer = options.headerContainer;

        this._headerContainer.addEventListener('mousedown', (e: MouseEvent) => {
            const target = <HTMLElement>e.target;
            if (!target.classList.contains('o365-sizer')) { return; }

            // TODO: Layout locking
            const generateGetBoundingClientRect = (x: number, y: number) => {
                return () => ({
                    width: 0,
                    height: 0,
                    top: y,
                    right: x,
                    bottom: y,
                    left: x,
                });
            };


            const resizeTooltip = document.createElement('span');
            resizeTooltip.classList.add('text-bg-primary', 'px-1', 'rounded-1')
            resizeTooltip.style.zIndex = '1000';
            document.body.append(resizeTooltip);

            const setTooltipValue = (value: number) => {
                resizeTooltip.innerText = `${value}px`;
            };

            const popperVirtualElement = {
                getBoundingClientRect: generateGetBoundingClientRect(e.x, e.y)
            };

            const popperInstance = createPopper(popperVirtualElement, resizeTooltip, {
                placement: 'bottom-end',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [-5, 10]
                        }
                    }
                ],
            });

            const gridControl = this._getGridControl();

            if (gridControl.container) {
                gridControl.container.classList.add('resizing');
                gridControl.container.style.cursor = 'col-resize';
                gridControl.container.style.userSelect = 'none';
            }

            const item = target;
            const column = gridControl.columns.columns.find(col => col.colId === item.getAttribute('o365-field'));

            setTooltipValue(column.width);
            const onMouseMove = (e: MouseEvent) => {

                window.requestAnimationFrame(() => {
                    let width: number;
                    if (this.useLeftColumnSizers || column.pinned === 'right') {
                        width = (item.parentElement.getBoundingClientRect().x + item.parentElement.getBoundingClientRect().width) - e.clientX;
                    } else {
                        width = e.clientX - item.parentElement.getBoundingClientRect().x;
                    }
                    if (width >= column.minWidth) {
                        column.width = width;
                        setTooltipValue(width);
                    } else if (column.minWidth !== column.width) {
                        column.width = column.minWidth;
                        setTooltipValue(column.width);
                    }
                    gridControl.setViewPortWidth();

                    popperVirtualElement.getBoundingClientRect = generateGetBoundingClientRect(e.x, e.y);
                    popperInstance.update();
                });

            };
            document.addEventListener('mousemove', onMouseMove);
            const onMouseUp = () => {
                if (gridControl.container) {
                    gridControl.container.classList.remove('resizing');
                    gridControl.container.style.cursor = '';
                    gridControl.container.style.userSelect = '';
                }
                document.removeEventListener('mousemove', onMouseMove);
                document.removeEventListener('mouseup', onMouseUp);
                popperInstance.destroy();
                resizeTooltip.remove();
                console.log('mouse up')
                // lockResolve();h  
            };

            document.addEventListener('mouseup', onMouseUp);
            e.preventDefault();
            e.stopPropagation();
        });
    }

    /** Clean up process, called on before unmount */
    destroy() {

    }

    /** Handler for header cell click */
    handleHeaderClick(e: MouseEvent, col: BaseColumn) {
        if (col.sortable) {
            e.preventDefault();
            const gridCOntrol = this._getGridControl();

            const orgSort = col.sort;
            switch (col.sort) {
                case 'asc':
                    col.sort = 'desc';
                    break;
                case 'desc':
                default:
                    col.sort = 'asc';
            }

            if (e.ctrlKey) {
                if (orgSort == null) {
                    const sortingCols = gridCOntrol.columns.columns.filter(x => {
                        if (x.sort != null) {
                            if (x.sortOrder == null) { x.sortOrder = 1; }
                            return true;
                        } else {
                            return false;
                        }
                    });
                    col.sortOrder = sortingCols.length;
                }
            } else {
                gridCOntrol.columns.columns.forEach(x => x.sortOrder = null);
                gridCOntrol.columns.columns.forEach(x => {
                    if (x.colId !== col.colId) {
                        x.sort = null;
                    }
                });
            }

            gridCOntrol.arrayData.applySort();
        }
    }

    handleColumnPin(column: BaseColumn, pinned: 'left' | 'right') {
        if (column.pinned === pinned) { return; }
        let pinnedIndex = -1;
        const gridControl = this._getGridControl();

        const getLeftIndex = () => {
            gridControl.columns.columns.forEach((col, index) => {
                if (col.pinned === 'left') { pinnedIndex = index; }
            });
        };

        const getRightIndex = () => {
            gridControl.columns.columns.every((col, index) => {
                if (col.pinned === 'right') {
                    pinnedIndex = index;
                    return false;
                } else {
                    return true;
                }
            });
        };

        switch (pinned) {
            case 'left':
                getLeftIndex();
                //gridControl.columns.setColumnOrder(column, pinnedIndex + 1)
                break;
            case 'right':
                getRightIndex();
                if (pinnedIndex === -1) { pinnedIndex = gridControl.columns.columns.length }
                //gridControl.columns.setColumnOrder(column, pinnedIndex - 1);
                break;
            default:
                if (column.pinned === 'left') {
                    getLeftIndex();
                    if (pinnedIndex === -1) { pinnedIndex = 0; }
                } else {
                    getRightIndex();
                    if (pinnedIndex === -1) { pinnedIndex = gridControl.columns.columns.length }
                    //pinnedIndex = gridControl.columns[pinnedIndex].field === 'o365_Action' ? pinnedIndex - 1 : pinnedIndex;
                }
            //gridControl.columns.setColumnOrder(column, pinnedIndex - 1);
        }
        column.pinned = pinned;
        gridControl.columns.updateColumnArrays();
    }
}